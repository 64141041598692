// src/App.js
import React from 'react';
// import Home from './pages/Home';
import ComingSoon from './pages/ComingSoon';

const App = () => {
  return (
    <div className="App">
      {/*  <Home /> */}
      <ComingSoon />
    </div>
  );
};

export default App;
